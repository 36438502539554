<template>
  <footer id="footer" class="footer fixed-bottom">
    <div class="copyright">
      &copy; Copyright <strong><span>NSV DEV SOLUTIONS</span></strong>. All Rights Reserved
    </div>
    <div class="credits">
      <!-- All the links in the footer should remain intact. -->
      <!-- You can delete the links only if you purchased the pro version. -->
      <!-- Licensing information: https://bootstrapmade.com/license/ -->
      <!-- Purchase the pro version with working PHP/AJAX contact form: https://bootstrapmade.com/nice-admin-bootstrap-admin-html-template/ -->
      Designed by <a href="https://filipensvdev.com.br/" target="_blank">NSV DEV SOLUTIONS</a>
    </div>
  </footer><!-- End Footer -->
</template>

<script>
export default {
  name:'Footer'
}
</script>

<style scoped>
/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

.footer {
  position: relative; /* Define a posição fixa */
  bottom: 0; /* Coloca o footer no final da página */
  left: 0; /* Alinha o footer à esquerda */
  width: calc(100% - 300px); /* Faz com que o footer tenha a largura total da página menos 300px */
  padding: 20px 0; /* Espaçamento interno superior e inferior */
  font-size: 14px;
  transition: all 0.3s;
  border-top: 1px solid #cddfff;
  z-index: 1; /* Garante que o footer esteja sobreposto a outros elementos */
}

@media (max-width: 1200px) {
  .footer {
    width: 100%; /* Reverte para largura total quando a largura da tela for menor ou igual a 1200px */
  }
}

.footer .copyright {
  text-align: center;
  color: #012970;
}

.footer .credits {
  padding-top: 5px;
  text-align: center;
  font-size: 13px;
  color: #012970;
}

#backTop {
  background-color: black;
}

</style>