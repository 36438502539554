<template>

  <div id="main">

    <div class="pagetitle">
      <h1>Resumo</h1>
    </div>
    <br>

    <!-- Filtro no Back-end -->
    <div class="card col-md-12">
      <div class="card-body">
        <div class="row">
          <div class="col-md-4">
            <label for="start_date" class="form-label">Data Início</label>
            <input type="date" v-model="filterStartPeriod" class="form-control" id="start_date" :disabled="loading"/>
          </div>

          <div class="col-md-4">
            <label for="end_date" class="form-label">Data Fim</label>
            <input type="date" v-model="filterEndPeriod" class="form-control" id="end_date" :disabled="loading"/>
          </div>

          <div class="col-md-3" style="margin-top: 32px;">
            <button v-if="!loading" class="btn btn-primary" id="btnSub" @click="graphData()" :disabled="loading || filterStartPeriod == '' || filterEndPeriod == ''">Buscar</button>
            <button v-else class="btn btn-primary" id="btnSub" disabled><span class="spinner-border spinner-border-sm"></span> Buscando...</button>
          </div>
        </div>
      </div>
    </div>
    <br>

    <div class="row" v-if="!loading">
      <!-- GRÁFICO NF21 -->
      <div class="col-md-6 mb-2">
        <div v-if="nf21Data.length > 1" class="card">
          <h5 class="card-title mt-1 text-center">NF21</h5>
          <VueApexCharts height="250" :options="chartDonutNf21" :series="seriesDonutNf21" type="bar"></VueApexCharts>

          <div class="card-body">
            <table class="table table-hover table-bordered">
              <thead class="text-center table-dark">
                <tr>
                  <th scope="col">Situação</th>
                  <th scope="col">Qtd.</th>
                  <th scope="col">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in nf21Data" :key="item">
                  <td :class="{ 'bold-total': item.situacao === 'Total' }">{{ item.situacao }}</td>
                  <td :class="{ 'bold-total': item.situacao === 'Total' }">{{ item.contagem }}</td>
                  <td :class="{ 'bold-total': item.situacao === 'Total' }">{{ formataValor(item.valor) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="card">
          <div class="card-body">
            <h5 class="card-title mt-1 text-center">NF21</h5>
            <hr>
            <hr>
            <table class="table table-hover table-bordered">
              <thead class="text-center table-dark">
                <tr>
                  <th scope="col">Situação</th>
                  <th scope="col">Qtd.</th>
                  <th scope="col">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="3" class="text-center"><b>Sem dados no período.</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- GRÁFICO NF55 -->
      <div class="col-md-6 mb-2">
        <div v-if="nf55Data.length > 1" class="card">
          <h5 class="card-title mt-1 text-center">NF55</h5>
          <VueApexCharts height="250" :options="chartDonutNf55" :series="seriesDonutNf55" type="bar"></VueApexCharts>

          <div class="card-body">
            <table class="table table-hover table-bordered">
              <thead class="text-center table-dark">
                <tr>
                  <th scope="col">Situação</th>
                  <th scope="col">Qtd.</th>
                  <th scope="col">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in nf55Data" :key="item">
                  <td :class="{ 'bold-total': item.situacao === 'Total' }">{{ item.situacao }}</td>
                  <td :class="{ 'bold-total': item.situacao === 'Total' }">{{ item.contagem }}</td>
                  <td :class="{ 'bold-total': item.situacao === 'Total' }">{{ formataValor(item.valor) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="card">
          <div class="card-body">
            <h5 class="card-title mt-1 text-center">NF55</h5>
            <hr>
            <hr>
            <table class="table table-hover table-bordered">
              <thead class="text-center table-dark">
                <tr>
                  <th scope="col">Situação</th>
                  <th scope="col">Qtd.</th>
                  <th scope="col">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="3" class="text-center"><b>Sem dados no período.</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <!-- GRÁFICO NFSE -->
      <div class="col-md-6 mb-2">
        <div v-if="nfseData.length > 1" class="card">
          <h5 class="card-title mt-1 text-center">NFSE</h5>
          <VueApexCharts height="250" :options="chartDonutNfse" :series="seriesDonutNfse" type="bar"></VueApexCharts>

          <div class="card-body">
            <table class="table table-hover table-bordered">
              <thead class="text-center table-dark">
                <tr>
                  <th scope="col">Situação</th>
                  <th scope="col">Qtd.</th>
                  <th scope="col">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in nfseData" :key="item">
                  <td :class="{ 'bold-total': item.situacao === 'Total' }">{{ item.situacao }}</td>
                  <td :class="{ 'bold-total': item.situacao === 'Total' }">{{ item.contagem }}</td>
                  <td :class="{ 'bold-total': item.situacao === 'Total' }">{{ formataValor(item.valor) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="card">
          <div class="card-body">
            <h5 class="card-title mt-1 text-center">NFSE</h5>
            <hr>
            <hr>
            <table class="table table-hover table-bordered">
              <thead class="text-center table-dark">
                <tr>
                  <th scope="col">Situação</th>
                  <th scope="col">Qtd.</th>
                  <th scope="col">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="3" class="text-center"><b>Sem dados no período.</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>      

      <!-- GRÁFICO NOTA DEBITO -->
      <div class="col-md-6 mb-2">
        <div v-if="nDebitoData.length > 1" class="card">
          <h5 class="card-title mt-1 text-center">Nota de Débito</h5>
          <VueApexCharts height="250" :options="chartDonutNDebito" :series="seriesDonutNDebito" type="bar"></VueApexCharts>

          <div class="card-body">
            <table class="table table-hover table-bordered">
              <thead class="text-center table-dark">
                <tr>
                  <th scope="col">Situação</th>
                  <th scope="col">Qtd.</th>
                  <th scope="col">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in nDebitoData" :key="item">
                  <td :class="{ 'bold-total': item.situacao === 'Total' }">{{ item.situacao }}</td>
                  <td :class="{ 'bold-total': item.situacao === 'Total' }">{{ item.contagem }}</td>
                  <td :class="{ 'bold-total': item.situacao === 'Total' }">{{ formataValor(item.valor) }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div v-else class="card">
          <div class="card-body">
            <h5 class="card-title mt-1 text-center">Nota de Débito</h5>
            <hr>
            <hr>
            <table class="table table-hover table-bordered">
              <thead class="text-center table-dark">
                <tr>
                  <th scope="col">Situação</th>
                  <th scope="col">Qtd.</th>
                  <th scope="col">Valor</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td colspan="3" class="text-center"><b>Sem dados no período.</b></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>      
    </div>   
    
  </div>

</template>

<script>
import { apiJson } from "@/services/api"
import VueApexCharts from "vue3-apexcharts";

export default {
  name: 'Resumo',
  data() {
    return {
      loading: false,
      filterStartPeriod: "",
      filterEndPeriod: "",
      nf21Data: [],
      nfseData: [],
      nf55Data: [],
      nDebitoData: [],
      seriesDonutNf21: [{
        name: 'Quantidade',
        data: []
      }],
      chartDonutNf21: {},
      seriesDonutNfse: [{
        name: 'Quantidade',
        data: []
      }],
      chartDonutNfse: {},
      seriesDonutNf55: [{
        name: 'Quantidade',
        data: []
      }],
      chartDonutNf55: {},
      seriesDonutNDebito: [{
        name: 'Quantidade',
        data: []
      }],
      chartDonutNDebito: {},
    };
  },
  components: {
    VueApexCharts,
  },
  // async mounted() {
  //   this.loading = true

  //   await this.graphData();    
    
  //   await Promise.all([      
  //     this.graphDataNf21(),
  //     this.graphDataNfse(),
  //     this.graphDataNf55(),
  //     this.graphDataNDebito()
  //   ]).then(() => {
  //     this.loading = false;
  //   }).catch(error => {
  //     console.error('Erro:', error);
  //     this.loading = false;
  //   });
  // },
  mounted() {
    // this.graphData()
  },
  methods: {
    graphData () {
      this.loading = true;

      if (this.filterStartPeriod == '' || this.filterEndPeriod == '') {
        this.$store.commit('setAlert', { show: true, status: false, msg: 'Preencha a Emissão corretamente.' });
        return
      }

      let formData = {
        start_date: this.filterStartPeriod,
        end_date: this.filterEndPeriod
        // "start_date": "2024-03-01",
        // "end_date": "2024-03-30"
      }

      apiJson.post("reports/financeiro-resumo", formData).then((response) => {
        if (response.data.error) {
          this.$store.commit('setAlert', { show: true, status: false, msg: response.data.error });
          this.loading = false
          return
        }

        this.nf21Data = Object.keys(response.data.nf21).length <= 1 ? [] : response.data.nf21;
        this.nfseData = Object.keys(response.data.nfse).length <= 1 ? [] : response.data.nfse;
        this.nf55Data = Object.keys(response.data.nf55).length <= 1 ? [] : response.data.nf55;
        this.nDebitoData = Object.keys(response.data.nota_debito).length <= 1 ? [] : response.data.nota_debito;

        this.graphDataNf21()
        this.graphDataNfse()
        this.graphDataNf55()
        this.graphDataNDebito()

        setTimeout(() => {
          this.loading = false;
        }, 1000);
        return;

      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 404) {
            this.$store.commit('setAlert', { show: true, status: false, msg: error.response.data.message });
            this.loading = false;
          }
          console.log(error.response)
        } else {
          console.log(error)
        }
        this.loading = false
      })
    },
    // async traiteGraphs() {
    //   await Promise.all([      
    //     this.graphDataNf21(),
    //     this.graphDataNfse(),
    //     this.graphDataNf55(),
    //     this.graphDataNDebito()
    //   ]).then(() => {
    //     this.loading = false;
    //   }).catch(error => {
    //     console.error('Erro:', error);
    //     this.loading = false;
    //   });
    // },
    graphDataNf21() {
      let label = [];
      let total = 0;

      for (let item of this.nf21Data) {
        if (item.situacao != 'Total') {
          this.seriesDonutNf21[0].data.push(item.contagem)
          label.push([item.situacao])
        } else {
          total = item.contagem;
        }       
      }

      this.chartDonutNf21 = {
        chart: {
          height: 350,
          type: 'bar',
        },
        colors: ['#4cd137'],
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let perc = (val * 100) / total;
            return perc.toFixed(2) + "%";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        
        xaxis: {
          categories: label,
          position: 'top',
          offsetY: -12, // Adiciona uma margem para baixo das etiquetas
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          }        
        }
      }
    },
    graphDataNfse() {
      let label = [];
      let total = 0;

      for (let item of this.nfseData) {
        if (item.situacao != 'Total') {
          this.seriesDonutNfse[0].data.push(item.contagem)
          label.push([item.situacao])
        } else {
          total = item.contagem;
        }       
      }

      this.chartDonutNfse = {
        chart: {
          height: 350,
          type: 'bar',
        },
        colors: ['#4cd137'],
        plotOptions: {
          bar: {
            borderRadius: 10,
            columnWidth: '40%', // Define a largura máxima das colunas
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let perc = (val * 100) / total;
            return perc.toFixed(2) + "%";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },        
        xaxis: {
          categories: label,
          position: 'top',
          offsetY: -12, // Adiciona uma margem para baixo das etiquetas
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          }        
        }
      }
    },
    graphDataNf55() {
      let label = [];
      let total = 0;

      for (let item of this.nf55Data) {
        if (item.situacao != 'Total') {
          this.seriesDonutNf55[0].data.push(item.contagem)
          label.push([item.situacao])
        } else {
          total = item.contagem;
        }       
      }

      this.chartDonutNf55 = {
        chart: {
          height: 350,
          type: 'bar',
        },
        colors: ['#4cd137'],
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let perc = (val * 100) / total;
            return perc.toFixed(2) + "%";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },
        
        xaxis: {
          categories: label,
          position: 'top',
          offsetY: -12, // Adiciona uma margem para baixo das etiquetas
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          }        
        }
      }
    },
    graphDataNDebito() {
      let label = [];
      let total = 0;

      for (let item of this.nDebitoData) {
        if (item.situacao != 'Total') {
          this.seriesDonutNDebito[0].data.push(item.contagem)
          label.push([item.situacao])
        } else {
          total = item.contagem;
        }       
      }

      this.chartDonutNDebito = {
        chart: {
          height: 350,
          type: 'bar',
        },
        colors: ['#4cd137'],
        plotOptions: {
          bar: {
            borderRadius: 10,
            dataLabels: {
              position: 'top', // top, center, bottom
            },
          }
        },
        dataLabels: {
          enabled: true,
          formatter: function (val) {
            let perc = (val * 100) / total;
            return perc.toFixed(2) + "%";
          },
          offsetY: -20,
          style: {
            fontSize: '12px',
            colors: ["#304758"]
          }
        },        
        xaxis: {
          categories: label,
          position: 'top',
          offsetY: -12, // Adiciona uma margem para baixo das etiquetas
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            fill: {
              type: 'gradient',
              gradient: {
                colorFrom: '#D8E3F0',
                colorTo: '#BED1E6',
                stops: [0, 100],
                opacityFrom: 0.4,
                opacityTo: 0.5,
              }
            }
          },
          tooltip: {
            enabled: true,
          }
        },
        yaxis: {
          axisBorder: {
            show: false
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: false,
            formatter: function (val) {
              return val;
            }
          }        
        }
      }
    },
    formataValor(valor) {
      // Verifica se o valor é um número
      if (typeof valor !== "number") {
        // Tenta converter para número
        valor = parseFloat(valor)
        // Verifica se a conversão foi bem-sucedida
        if (isNaN(valor)) {
          // Se não for um número válido, retorna o valor original
          return valor
        }
      }
      // Formata o valor como dinheiro
      return valor.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
    }
  }
}

</script>

<style scoped>
  .card {
    box-shadow: 0 3px 4px rgba(0, 0, 4, 10);
    /* min-height: 230px; */
    padding: 2px;
    /* border: 2px solid silver; */
    /* background-color: white !important; */
  }
  .bold-total {
    font-weight: bold;
  }
</style>