<template>
  <!-- ======= Sidebar ======= -->
  <aside id="sidebar" class="sidebar">
    <ul class="sidebar-nav" id="sidebar-nav">

      <!-- Relatórios -->
      <li class="nav-item">
        <router-link class="nav-link collapsed" data-bs-target="#dropdown-relatorios" data-bs-toggle="collapse" to="#">
          <i class="bi bi-bar-chart-line"></i><span>Relatórios Gráficos</span><i class="bi bi-chevron-down ms-auto"></i>
        </router-link>

        <ul id="dropdown-relatorios" class="nav-content collapse" data-bs-parent="#sidebar-nav">
          <li class="nav-heading">Financeiro</li>

          <li>
            <router-link to="/relatorios/financeiro-resumo">
              <span :class="$route.path == '/relatorios/financeiro-resumo' ? 'menu-route-active' : ''"><i class="bi bi-arrow-right"></i>Resumo</span>
            </router-link>
          </li>

          <li>
            <router-link to="/relatorios/financeiro-faturamento">
              <span :class="$route.path == '/relatorios/financeiro-faturamento' ? 'menu-route-active' : ''"><i class="bi bi-arrow-right"></i>Faturamento</span>
            </router-link>
          </li>
        </ul>
      </li>

      <!-- Financeiro -->
      <li class="nav-item">
        <router-link class="nav-link collapsed" data-bs-target="#dropdown-financeiro" data-bs-toggle="collapse" to="#">
          <i class="bi bi-journal-text"></i><span>Financeiro</span><i class="bi bi-chevron-down ms-auto"></i>
        </router-link>
        
        <ul id="dropdown-financeiro" class="nav-content collapse" data-bs-parent="#sidebar-nav">

          <!-- Faturamento -->
          <li class="nav-heading">Faturamento</li>

          <li>
            <router-link to="/financeiro/nf21">
              <span :class="$route.path == '/financeiro/nf21' ? 'menu-route-active' : ''"><i class="bi bi-arrow-right"></i>NF21</span>
            </router-link>
          </li>          

          <li>
            <router-link to="/financeiro/nfse">
              <span :class="$route.path == '/financeiro/nfse' ? 'menu-route-active' : ''"><i class="bi bi-arrow-right"></i>NFSE</span>
            </router-link>
          </li>

          <li>
            <router-link to="/financeiro/nf55">
              <span :class="$route.path == '/financeiro/nf55' ? 'menu-route-active' : ''"><i class="bi bi-arrow-right"></i>NF55</span>
            </router-link>
          </li>

          <li>
            <router-link to="/financeiro/nota-debito">
              <span :class="$route.path == '/financeiro/nota-debito' ? 'menu-route-active' : ''"><i class="bi bi-arrow-right"></i>Nota de Débito</span>
            </router-link>
          </li>

          <!-- Contas a Receber -->
          <li class="nav-heading">Contas a Receber</li>

          <li>
            <router-link to="/financeiro/boletos">
              <span :class="$route.path == '/financeiro/boletos' ? 'menu-route-active' : ''"><i class="bi bi-arrow-right"></i>Boletos</span>
            </router-link>
          </li>

          <!-- <li>
            <router-link to="/financeiro/contratos">
              <i class="bi bi-arrow-right"></i><span>Contratos</span>
            </router-link>
          </li> -->
        </ul>
      </li>

      <!-- <li class="nav-item">
        <router-link to="/users-profile" class="nav-link collapsed">
          <i class="bi bi-person"></i>
          <span>Menu Click</span>
        </router-link>
      </li> -->
    </ul>
  </aside><!-- End Sidebar-->
</template>


<script>
export default {
  name: 'SideBar',
}
</script>

<style>

/*--------------------------------------------------------------
# Sidebar
--------------------------------------------------------------*/
.sidebar {
  position: fixed;
  top: 60px;
  left: 0;
  bottom: 0;
  width: 300px;
  z-index: 996;
  transition: all 0.3s;
  padding: 20px;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: #aab7cf transparent;
  box-shadow: 0px 0px 20px rgba(1, 41, 112, 0.1);
  /* background-color: #ffffff; */
  background-color: #333333;
}

@media (max-width: 1199px) {
  .sidebar {
    left: -300px;
  }
}

.sidebar::-webkit-scrollbar {
  width: 5px;
  height: 8px;
  background-color: #fff;
}

.sidebar::-webkit-scrollbar-thumb {
  background-color: #aab7cf;
}

@media (min-width: 1200px) {

  #main,
  #footer {
    margin-left: 300px;
  }
}

@media (max-width: 1199px) {
  .toggle-sidebar .sidebar {
    left: 0;
  }
}

@media (min-width: 1200px) {

  .toggle-sidebar #main,
  .toggle-sidebar #footer {
    margin-left: 0;
  }

  .toggle-sidebar .sidebar {
    left: -300px;
  }
}

.sidebar-nav {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav li {
  padding: 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-item {
  margin-bottom: 5px;
}

.sidebar-nav .nav-heading {
  font-size: 11px;
  text-transform: uppercase;
  color: #899bbd;
  font-weight: 600;
  margin: 10px 0 5px 15px;
}

.sidebar-nav .nav-link {
  display: flex;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  /* color: #000; */
  color: #fff;
  transition: 0.3;
  /* background: #03db27; */
  background: #0d9c25;
  padding: 10px 15px;
  border-radius: 4px;
}

.sidebar-nav .nav-link i {
  font-size: 16px;
  margin-right: 10px;
  /* color: #000; */
  color: #fff;
}


.sidebar-nav .nav-link.collapsed {
  color: #fff;
  background: #0d9c25;
}

.sidebar-nav .nav-link.collapsed i {
  color: #fff;
}

.sidebar-nav .nav-link:hover {
  color: #000;
  background: #03db27;
}

.sidebar-nav .nav-link:hover i {
  color: #000;
}

.sidebar-nav .nav-link .bi-chevron-down {
  margin-right: 0;
  transition: transform 0.2s ease-in-out;
}

.sidebar-nav .nav-link:not(.collapsed) .bi-chevron-down {
  transform: rotate(180deg);
}

.sidebar-nav .nav-content {
  padding: 5px 0 0 0;
  margin: 0;
  list-style: none;
}

.sidebar-nav .nav-content a {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  transition: 0.3;
  padding: 10px 0 10px 40px;
  transition: 0.3s;
}

.sidebar-nav .nav-content a i {
  font-size: 12px;
  margin-right: 8px;
  line-height: 0;
  border-radius: 50%;
}

.sidebar-nav .nav-content a:hover,
.sidebar-nav .nav-content a.active {
  color: #03db27;
}

.sidebar-nav .nav-content a.active i {
  background-color: #03db27;
}

.menu-route-active {
  color: #03db27;
  font-weight: bold;
}


</style>