import { createRouter, createWebHistory } from 'vue-router'

import Login from '../views/Login.vue'
import Home from '../views/system/Home.vue'
import NF21 from '@/views/system/financeiro/faturamento/NF21.vue'
import NFSE from '@/views/system/financeiro/faturamento/NFSE.vue'
import NF55 from '@/views/system/financeiro/faturamento/NF55.vue'
import NotaDebito from '@/views/system/financeiro/faturamento/NotaDebito.vue'
import Contrato from '@/views/system/financeiro/faturamento/Contrato.vue'
import Boletos from '@/views/system/financeiro/contas-receber/Boletos.vue'
import RelatFinResumo from '@/views/system/relatorios-graficos/Resumo.vue'
import RelatFaturamento from '@/views/system/relatorios-graficos/Faturamento.vue'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    
  },
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: { requiresAuth: true }
  },
  {
    path: '/financeiro/nf21',
    name: 'NF21',
    component: NF21,
    meta: { requiresAuth: true }
  },
  {
    path: '/financeiro/nfse',
    name: 'NFSE',
    component: NFSE,
    meta: { requiresAuth: true }
  },
  {
    path: '/financeiro/nf55',
    name: 'NF55',
    component: NF55,
    meta: { requiresAuth: true }
  },
  {
    path: '/financeiro/nota-debito',
    name: 'NotaDebito',
    component: NotaDebito,
    meta: { requiresAuth: true }
  },
  {
    path: '/financeiro/boletos',
    name: 'Boletos',
    component: Boletos,
    meta: { requiresAuth: true }
  },
  {
    path: '/financeiro/contratos',
    name: 'Contrato',
    component: Contrato,
    meta: { requiresAuth: true }
  },
  {
    path: '/relatorios/financeiro-resumo',
    name: 'RelatFinResumo',
    component: RelatFinResumo,
    meta: { requiresAuth: true }
  },
  {
    path: '/relatorios/financeiro-faturamento',
    name: 'RelatFaturamento',
    component: RelatFaturamento,
    meta: { requiresAuth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

// Adicione um guarda de navegação beforeEach para verificar a autenticação
router.beforeEach((to, from, next) => {
  let token = localStorage.getItem("token")

  if (to.meta.requiresAuth && (!token || token == null || token == undefined || token == '')) {
    // Se a rota requer autenticação e o usuário não está autenticado, redirecione para a página de login ou outra página de sua escolha
    next('/login')
  } else {
    // Caso contrário, permita a navegação
    next()
  }
})

export default router
