<template>
  <div class="d-flex justify-content-center mt-3">
    <nav aria-label="Page navigation example">
      <ul class="pagination">
        <li class="page-item" v-if="currentPage > 1">
          <a class="page-link bg-light" href="#" aria-label="First" @click="firstPage">
            <span aria-hidden="true"><<</span>
          </a>
        </li>
        <li class="page-item" v-if="currentPage > 1">
          <a class="page-link bg-light" href="#" aria-label="Previous" @click="prevPage">
            <span aria-hidden="true"><</span>
            <!-- <span aria-hidden="true">&laquo;</span> -->
          </a>
        </li>
        <li class="page-item" v-for="page in displayedPages" :key="page" :class="{ active: page === currentPage }">
          <a class="page-link" href="#" @click="gotoPage(page)">{{ page }}</a>
        </li>
        <li class="page-item" v-if="currentPage < totalPages">
          <a class="page-link bg-light" href="#" aria-label="Next" @click="nextPage">
            <span aria-hidden="true">></span>
            <!-- <span aria-hidden="true">&raquo;</span> -->
          </a>
        </li>
        <li class="page-item" v-if="currentPage < totalPages">
          <a class="page-link bg-light" href="#" aria-label="Last" @click="lastPage">
            <span aria-hidden="true">>></span>            
          </a>
        </li>
      </ul>
    </nav>

  </div>
  <div class="text-center" style="margin-top: -15px; margin-bottom: -15px;">
    <span class="item-count">{{ currentPage.toLocaleString('pt-BR') }} DE {{ totalPages.toLocaleString('pt-BR') }} PÁGINAS</span>
    <span class="item-count">TOTAL DE {{ list.length.toLocaleString('pt-BR') }} ITENS</span> 
  </div>

</template>

<script>
export default {
  name: 'Pagination',
  emits: ['updatePaginatedItems'],
  props: ['list'],
  data() {
    return {
      itemsPerPage: 10, // Defina o número de itens por página aqui
      currentPage: 1,
      // maxDisplayedPages: 6 // Defina o número máximo de páginas exibidas aqui
      maxDisplayedPages: this.calculateMaxDisplayedPages()
    }
  },
  mounted () {
    this.$emit('updatePaginatedItems', this.paginatedItems);
    window.addEventListener('resize', this.handleWindowResize);
  },
  watch: {
    list() {
      this.$emit('updatePaginatedItems', this.paginatedItems)
      this.gotoPage(1)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  computed: {
    paginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.list.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.list.length / this.itemsPerPage);
    },
    displayedPages() {
      const midPoint = Math.ceil(this.maxDisplayedPages / 2);
      let startPage = Math.max(1, this.currentPage - midPoint + 1 - (this.maxDisplayedPages % 2));
      const endPage = Math.min(this.totalPages, startPage + this.maxDisplayedPages - 1);

      // Ajuste para manter o intervalo correto quando estiver perto do final
      startPage = Math.max(1, endPage - this.maxDisplayedPages + 1);

      return Array.from({ length: endPage - startPage + 1 }, (_, index) => startPage + index);
    }
  },
  methods: {
    calculateMaxDisplayedPages() {
      // Obtenha a largura da tela
      const screenWidth = window.innerWidth;

      // Defina o número máximo de páginas com base na largura da tela
      if (screenWidth < 768) { // Para dispositivos menores que 768px (tamanho de tela de celular)
        return 5;
      } else if (screenWidth < 992) { // Para dispositivos entre 768px e 991px (tamanho de tela de tablet)
        return 8;
      } else { // Para dispositivos maiores que 992px (tamanho de tela de desktop)
        return 10;
      }
    },
    handleWindowResize() {
      // Atualize o número máximo de páginas exibidas quando o tamanho da tela for alterado
      this.maxDisplayedPages = this.calculateMaxDisplayedPages();
    },
    emitPaginatedItems() {
      this.$emit('updatePaginatedItems', this.paginatedItems);
    },
    gotoPage (page) {
      this.currentPage = page;
      this.emitPaginatedItems()
    },
    firstPage() {
      this.currentPage = 1;
      this.emitPaginatedItems()
    },
    lastPage() {
      this.currentPage = this.totalPages;
      this.emitPaginatedItems()
    },
    prevPage () {
      this.currentPage--;
      this.emitPaginatedItems()
    },
    nextPage () {
      this.currentPage++;
      this.emitPaginatedItems()
    }
  }
}
</script>

<style scoped>
.item-count {
  font-size: 12px;
  color: #5c5d5f;
  font-weight: bold;
  display: block;
}

</style>