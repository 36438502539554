//* npm install axios
import axios from 'axios';
import store from '@/store'; 

const tokenJWT = 'Bearer ' + localStorage.getItem("token") || ''

const apiJson = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Authorization': tokenJWT,
    'Content-type': 'application/json'
  }
});

const apiMult = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Authorization': tokenJWT,
    'Content-type': 'multipart/form-data'
  }
});

// Interceptando as respostas do servidor
apiJson.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let msg = error.response.data.msg
    // Se a resposta do servidor for 401 (Token is Expired), faz logout automaticamente
    if (error.response.status === 401 && (msg == 'Token is Invalid' || msg == 'Token is Expired' || msg == 'Authorization Token not found')) {
      store.commit('setAlert', { show: true, status: null, msg: 'SUA SESSÃO EXPIROU OU O TOKEN É INVÁLIDO. POR FAVOR, FAÇA LOGIN NOVAMENTE.' });
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_email");
      localStorage.removeItem("is_admin");

      setTimeout(() => {
        if (window.location.pathname !== "/login") {
          window.location.href = "/login";
        }
      }, 5000)
    }
    return Promise.reject(error);
  }
);

apiMult.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    let msg = error.response.data.msg
    // Se a resposta do servidor for 401 (Token is Expired), faz logout automaticamente
    if (error.response.status === 401 && (msg == 'Token is Invalid' || msg == 'Token is Expired' || msg == 'Authorization Token not found')) {
      store.commit('setAlert', { show: true, status: null, msg: 'SUA SESSÃO EXPIROU OU O TOKEN É INVÁLIDO. POR FAVOR, FAÇA LOGIN NOVAMENTE.' });
      localStorage.removeItem("token");
      localStorage.removeItem("user_id");
      localStorage.removeItem("user_name");
      localStorage.removeItem("user_email");
      localStorage.removeItem("is_admin");

      setTimeout(() => {
        if (window.location.pathname !== "/login") {
          window.location.href = "/login";
        }
      }, 1000)
    }
    return Promise.reject(error);
  }
);

export { apiJson, apiMult };