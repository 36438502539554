<template>
  <div v-if="token">
    <!-- Modal de Alerta Global -->
    <AlertModal/>

    <Header/>
    <router-view/>
    <SideBar/>
    <Footer/>
  </div>
  <div v-else>
    <Login/>
  </div>
</template>

<script>
import Header from './components/layout/Header.vue'
import SideBar from './components/layout/SideBar.vue'
import Footer from './components/layout/Footer.vue'
import Login from './views/Login.vue'
import AlertModal from './components/global/AlertModal.vue'

export default {  
  name: 'App',
  data() {
    return {
      token: ''
    }
  },
  mounted () {
    this.token = localStorage.getItem('token')
  },
  watch: {
    'this.$store.state.token': function (oldvalue, newValue) {
      this.token = localStorage.getItem('token')
      // localStorage.removeItem('token')
    }
  },
  components: {
    SideBar,
    Header,
    Footer,
    Login,
    AlertModal
  }
}
</script>
