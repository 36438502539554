<template>
  <!-- Modal de Sincronização -->
    <div class="modal fade" id="syncModal" tabindex="-1" aria-labelledby="syncModalLabel" aria-hidden="true" data-bs-backdrop="static">
      <div class="modal-dialog">
        <div class="modal-content bg-dark text-white">
          <div class="modal-header">
            <h1 class="modal-title fs-5" id="syncModalLabel">
              SINCRONIZAÇÃO DE NOTAS POR PERÍODO
            </h1>
            <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button> -->
            <button type="button" class="btn btn-outline-light btn=sm" :disabled="loading" data-bs-dismiss="modal" aria-label="Close">
              <i class="bi bi-x-lg"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-6">
                <!-- <label for="period" class="form-label">Início do Período</label> -->
                <input type="month" class="form-control" id="period" v-model="selectedPeriod"/>
              </div>
              <div class="col-md-6">
                <button v-if="!loading" class="btn btn-primary w-100" @click="syncNotas()">
                  Sincronizar
                </button>
                <button v-else class="btn btn-primary w-100" disabled>
                  <b><span class="spinner-border spinner-border-sm text-dark"></span> Sincronizando...</b>
                </button>
              </div>

              <input type="date" class="form-control" id="start_period" v-model="startPeriod" hidden/>
              <input type="date" class="form-control" id="end_period" v-model="endPeriod" hidden/>
            </div>
            <div style="margin-top: 10px">
              <span class="text-danger" style="font-size: 12px; margin-top: 10px; display: block">
                <b><i class="bi bi-exclamation-triangle-fill"></i> Obs.: As informações atuais serão substituídas pelos novos dados.</b>
              </span>
              <span class="text-warning" style="font-size: 12px; margin-top: 10px">
                <b><i class="bi bi-info-circle-fill"></i> A depender da quantidade de Notas no período, poderá demorar alguns minutos.</b>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import { apiJson } from "@/services/api"

export default {
  name: "SyncData",
  props: ['name'],
  data() {
    return {
      // Loading
      loading: false,
      // Sincronização
      selectedPeriod: "",
      startPeriod: "",
      endPeriod: "",
    }
  },
  watch: {
    selectedPeriod(newValue) {
      const [year, month] = newValue.split("-")
      const firstDayOfMonth = new Date(year, month - 1, 1)
      this.startPeriod = firstDayOfMonth.toISOString().split("T")[0]

      const lastDayOfMonth = new Date(year, month, 0)
      this.endPeriod = lastDayOfMonth.toISOString().split("T")[0]
    },
  },
  methods: {
    syncNotas() {
      this.loading = true

      if (
        (this.selectedPeriod == "" || this.selectedPeriod == undefined) &&
        (this.startPeriod == "" || this.startPeriod == undefined) &&
        (this.endPeriod == "" || this.endPeriod == undefined)
      ) {
        this.$store.commit('setAlert', { show: true, status: false, msg: 'Preencha o período corretamente.' });
        this.loading = false;
        return
      }

      let formData = {
        start_date: this.startPeriod,
        end_date: this.endPeriod,
      }

      let route = {
        'nfse': 'nfse-petrolina',
        'nf21': 'nf21',
        'nf55': 'nf55',
        'notaDebito': 'nota-debito',
        'boleto': 'boleto',
      }

      apiJson.post("sync/" + route[this.name], formData).then((response) => {
        if (response.data.success) {
          this.$store.commit('setAlert', { show: true, status: true, msg: response.data.success });
          this.loading = false
          return          
        }

        if (response.data.error && response.data.status != 401) {
          this.$store.commit('setAlert', { show: true, status: false, msg: response.data.error });
          this.loading = false
          return
        }
      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status != 200) {
            this.$store.commit('setAlert', { show: true, status: false, msg: error.response.data.message });
          }
          console.log(error.response)
        } else {
          console.log(error)
        }
        this.loading = false
      })
    },
  }
}
</script>