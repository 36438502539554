<template>
  <div class="container">
    <!-- Modal - chamar modal: this.$store.commit('setAlert', {msg: 'Deu certo', status: true, show: true}); -->
    <div v-if="this.$store.state.alert.show" class="modalCustom">
      <div :class="'modalCustom-content text-light text-center ' + classModal[this.$store.state.alert.status]">
        <span v-if="!expiredToken" class="close" @click="fecharModal()">&times;</span>
        <span v-else class="countdown">{{ countdown }}</span>

        <h4 v-if="this.$store.state.alert.status"><i class="bi bi-check-circle-fill"></i> Sucesso!</h4>
        <h4 v-if="this.$store.state.alert.status === false"><i class="bi bi-exclamation-circle-fill"></i> Erro.</h4>
        <h4 v-if="this.$store.state.alert.status == null"><i class="bi bi-info-circle-fill"></i> Atenção!</h4><hr>
        <p><b>{{ this.$store.state.alert.msg }}</b></p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "AlertModal",
  data () {
    return {
      classModal: {
        'true': 'bg-success',
        'false': 'bg-danger',
        'null': 'bg-secondary',
      },
      expiredToken: false,
      countdown: 5 // Defina o tempo de contagem regressiva em segundos
    }
  },
  watch: {
    '$store.state.alert.msg': function(newMsg, oldMsg) {
      if (newMsg === 'SUA SESSÃO EXPIROU OU O TOKEN É INVÁLIDO. POR FAVOR, FAÇA LOGIN NOVAMENTE.') {
        this.expiredToken = true;
        this.startCountdown();
      }
    }
  },
  methods: {
    fecharModal () {
      this.$store.commit('setAlert', {msg: '', status: true, show: false})
    },
    startCountdown () {
      const timer = setInterval(() => {
        this.countdown--;
        if (this.countdown === 0) {
          clearInterval(timer);
          // Redirecionar para a página de login após a contagem regressiva
          window.location.href = "/login";
        }
      }, 1000); // Atualiza a cada segundo
    }
  }
}
</script>
<style scoped>
  .modalCustom {
    position: fixed; /* Permite o modal ficar sobreposto */
    z-index: 10000; /* Garante que o modal esteja sobre outros elementos */
    left: 0;
    top: 0;
    width: 100%; /* Cobrir toda a largura da tela */
    height: 100%; /* Cobrir toda a altura da tela */
    overflow: auto; /* Adiciona rolagem se o conteúdo do modal for muito grande */
    background-color: rgba(0,0,0,0.7); /* Cor de fundo do modal */
  }

  /* Conteúdo do modal */
  .modalCustom-content {
    margin: 15% auto; /* Centraliza o modal na tela */
    padding: 20px;
    border: 2px solid #ffffff;
    width: 45%; /* Largura do modal */
  }

  @media (max-width: 700px) {
    .modalCustom-content {
      width: 80%; /* Largura do modal quando a tela for menor que 1000px */
    }
  }

  /* Botão de fechar */
  .close {
    color: #ffffff;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
  }

  .countdown {
    color: #ffffff;
    float: right;
    font-size: 14px;
    font-weight: bold;
  }
</style>
