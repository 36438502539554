<template>
  <!-- Exportar Notas com a Prefeitura -->
  <div class="row" style="margin-top: 10px;">
    <div class="col text-start">
      <button v-if="!loading" type="button" class="btn btn-outline-success btn-sm" @click="exportExcel()">
        <b><i class="bi bi-filetype-xlsx"></i> Excel</b>
      </button>
      <button v-else type="button" class="btn btn-success btn-sm" :disabled="loading">
        <b><span class="spinner-border spinner-border-sm"></span> Exportando...</b>
      </button>
    </div>

    <div class="col text-end">
      <button type="button" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#syncModal">
        <b><i class="bi bi-arrow-repeat"></i> Sincronizar</b>
      </button>
    </div>      
  </div>
</template>

<script>
import { apiJson } from "@/services/api"

export default {
  name: "ExportData",
  props: ['name', 'filterStartPeriod', 'filterEndPeriod', 'param?'],
  data () {
    return {
      loading: false
    }
  },
  methods: {
    exportExcel() {
      this.loading = true
      let start = this.filterStartPeriod;
      let end = this.filterEndPeriod;
      
      if ((start == "" || start == undefined) && (end == "" || end == undefined)) {
        this.$store.commit('setAlert', { show: true, status: null, msg: 'Preencha a Competência corretamente.' });
        this.loading = false;
        setTimeout(() => {
          this.$store.commit('setAlert', { show: false, status: false, msg: '' });
        }, 5000);
        return
      }

      let formData = {
        start_date: start,
        end_date: end,        
      }

      if (this.name === 'boleto') {
        formData.boleto_avulso = this.param == undefined ? 'null' : this.param;
      }
// console.log(this.param)
// console.log(formData)
// return
      let route = {
        'nfse': 'xlsx-nfse',
        'nf21': 'xlsx-nf21',
        'nf55': 'xlsx-nf55',
        'notaDebito': 'xlsx-nota-debito',
        'boleto': 'xlsx-boleto',
      }

      apiJson.post("export/" + route[this.name], formData).then((response) => {
        let fileName = response.data.file_name
        window.location.href = process.env.VUE_APP_API_BASE_URL + 'export/download-exported-file/' + fileName

        setTimeout(() => {
          this.loading = false
        }, 10000);
        return
      })
      .catch((error) => {
        if (error.response && error.response.staus != 401) {
          this.$store.commit('setAlert', { show: true, status: false, msg: 'Houve algum problema na solicitação.'});
        }
        console.log('error.response: ',error.response)
        this.loading = false
      })
    },
  }
}
</script>