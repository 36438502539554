<template>

  <div id="main">

    <div class="pagetitle">
      <h1>Relatórios</h1>
    </div>
    <br>

    <div class="row">
      <div class="col-md-4 mb-2">
        <div class="card text-center">
          <h5 class="card-title mt-1">NF21</h5>
          <VueApexCharts :options="chartOptions" :series="series" type="line"></VueApexCharts>          
        </div>
      </div>

      <div class="col-md-4 mb-2">
        <div class="card text-center">
          <h5 class="card-title mt-1">NFSE</h5>
          <VueApexCharts :options="chartOptions1" :series="series1" type="bar"></VueApexCharts>       
        </div>
      </div>

      <div class="col-md-4 mb-2">
        <div class="card text-center">
          <h5 class="card-title mt-1">NF55</h5>
          <VueApexCharts :options="chartOptions2" :series="series2" type="donut"></VueApexCharts>          
        </div>
      </div>
    </div>   
    
  </div>

</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: 'Home',
  data() {
    return {
      series: [
        {
          name: "Sales",
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
        },
      ],
      chartOptions: {
        chart: {
          type: "line",
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
        },
      },
      series1: [
        {
          name: "Sales",
          data: [30, 40, 35, 50, 49, 60, 70, 91, 125],
        },
      ],
      chartOptions1: {
        chart: {
          type: "bar",
        },
        xaxis: {
          categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
        },
      },
      series2: [30, 40, 35, 50],
      chartOptions2: {
        chart: {
          type: "donut",
        },
        // xaxis: {
        //   categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep"],
        // },
      },
      
    };
  },
  components: {
    VueApexCharts,
  }
}

</script>

<style scoped>
  .card {
    box-shadow: 0 3px 4px rgba(0, 0, 4, 10);
    min-height: 230px;
    /* border: 2px solid silver; */
    /* background-color: white !important; */
  }
</style>