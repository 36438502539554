import { createStore } from 'vuex'

export default createStore({
  state: {
    alert: {
      show: false,
      status: true,
      msg: ''
    }
  },
  getters: {},
  mutations: {
    setAlert (state, obj) {
      state.alert = obj
    }
  },
  actions: {},
  modules: {}
})
