<template>

  <div id="main">

    <div class="pagetitle">
      <h1>Resumo do Faturamento</h1>
    </div>
    <br>
    
    <div class="card p-1">
      <h5 class="text-center">Faturamento</h5>
      <div class="table-responsive">
        <table class="table table-hover table-bordered text-center text-nowrap">
          <thead class="table-dark">
            <tr class="">
              <th scope="col">Cidade</th>
              <th scope="col">Varejo</th>
              <th scope="col">Atacado</th>
              <th scope="col">Licitação</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody class="table-light">
            <tr>
              <th>ARACI</th>
              <td>R$ 84.928,85</td>
              <td>R$ -</td>
              <td>R$ -</td>
              <td>R$ 84.928,85</td>
            </tr>
            <tr>
              <th>IPIRÁ</th>
              <td>R$ 40.335,73</td>
              <td>R$ 14.000,00</td>
              <td>R$ -</td>
              <td>R$ 54.335,73</td>
            </tr>
            <tr>
              <th>ITABERABA</th>
              <td>R$ 592.552,10</td>
              <td>R$ 19.710,32</td>
              <td>R$ 179,90</td>
              <td>R$ 612.442,32</td>
            </tr>
            <tr>
              <th>ITAETÉ</th>
              <td>R$ 57.396,39</td>
              <td>R$ -</td>
              <td>R$ -</td>
              <td>R$ 57.396,39</td>
            </tr>
            <tr>
              <th>JUAZEIRO</th>
              <td>R$ 333.299,43</td>
              <td>R$ 345.445,73</td>
              <td>R$ 2.192,92</td>
              <td>R$ 680.938,08</td>
            </tr>
            <tr>
              <th>PETROLINA</th>
              <td>R$ 799.636,71</td>
              <td>R$ 60.231,07</td>
              <td>R$ 35.536,10</td>
              <td>R$ 895.403,88</td>
            </tr>
            <tr>
              <th>RUY BARBOSA</th>
              <td>R$ 139.757,38</td>
              <td>R$ -</td>
              <td>R$ -</td>
              <td>R$ 139.757,38</td>
            </tr>
            <tr>
              <th>SENHOR DO BONFIM</th>
              <td>R$ 155.046,38</td>
              <td>R$ -</td>
              <td>R$ -</td>
              <td>R$ 155.046,38</td>
            </tr>
            <tr>
              <th>SERRINHA</th>
              <td>R$ 160.573,17</td>
              <td>R$ 5.000,00</td>
              <td>R$ -</td>
              <td>R$ 165.573,17</td>
            </tr>
            <tr>
                <th>TUCANO</th>
              <td>R$ 71.508,98</td>
              <td>R$ -</td>
              <td>R$ -</td>
              <td>R$ 71.508,98</td>
            </tr>
            <tr>
              <th>TOTAL</th>
              <td>R$ 2.435.035,12</td>
              <td>R$ 444.387,12</td>
              <td>R$ 37.908,92</td>
              <td>R$ 2.917.331,16</td>
            </tr>    
          </tbody>
        </table>
      </div>
      
      <h5 class="text-center">Base</h5>
      <div class="table-responsive">
        <table class="table table-hover table-bordered text-center text-nowrap">
          <thead class="table-dark">
            <tr class="">
              <th scope="col">Cidade</th>
              <th scope="col">Varejo</th>
              <th scope="col">Atacado</th>
              <th scope="col">Licitação</th>
              <th scope="col">Cortesia</th>
              <th scope="col">Total</th>
            </tr>
          </thead>
          <tbody class="table-light">
            <tr>
              <th>ARACI</th>
              <td>1307</td>
              <td>0</td>
              <td>0</td>
              <td>9</td>
              <th>1316</th>
            </tr>
            <tr>
              <th>IPIRÁ</th>
              <td>511</td>
              <td>4</td>
              <td>0</td>
              <td>22</td>
              <th>537</th>
            </tr>
            <tr>
              <th>ITABERABA</th>
              <td>6945</td>
              <td>33</td>
              <td>2</td>
              <td>62</td>
              <th>7042</th>
            </tr>
            <tr>
              <th>ITAETÉ</th>
              <td>661</td>
              <td>0</td>
              <td>3</td>
              <td>0</td>
              <th>664</th>
            </tr>
            <tr>
              <th>JUAZEIRO</th>
              <td>3685</td>
              <td>78</td>
              <td>118</td>
              <td>35</td>
              <th>3916</th>
            </tr>
            <tr>
              <th>PETROLINA</th>
              <td>10274</td>
              <td>63</td>
              <td>403</td>
              <td>114</td>
              <th>10854</th>
            </tr>
            <tr>
              <th>RUY BARBOSA</th>
              <td>1676</td>
              <td>1</td>
              <td>0</td>
              <td>7</td>
              <th>1684</th>
            </tr>
            <tr>
              <th>SENHOR DO BONFIM</th>
              <td>1944</td>
              <td>1</td>
              <td>1</td>
              <td>35</td>
              <th>1981</th>
            </tr>
            <tr>
              <th>SERRINHA</th>
              <td>2289</td>
              <td>1</td>
              <td>1</td>
              <td>41</td>
              <th>2332</th>
            </tr>
            <tr>
              <th>TUCANO</th>
              <td>1128</td>
              <td>1</td>
              <td>0</td>
              <td>11</td>
              <th>1140</th>
            </tr>
            <tr class="table-secondary">
              <th>TOTAL</th>
              <td>30420</td>
              <td>182</td>
              <td>525</td>
              <td>339</td>
              <th>31466</th>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Só varejo, Varejo Tab 1 / (Cortesia e Varejo) Tab 2 -->
      <h5 class="text-center">Ticket</h5>
      <div class="table-responsive">
        <table class="table table-hover table-bordered text-center text-nowrap">
          <thead class="table-dark">
            <tr class="">
              <th scope="col">Cidade</th>
              <th scope="col">Valor(%)</th>
            </tr>
          </thead>
          <tbody class="table-light">
            <tr>
                <th>ARACI</th>
                <td>R$ 64,54</td>
            </tr>
            <tr>
                <th>IPIRÁ</th>
                <td>R$ 75,68</td>
            </tr>
            <tr>
                <th>ITABERABA</th>
                <td>R$ 84,57</td>
            </tr>
            <tr>
                <th>ITAETÉ</th>
                <td>R$ 86,44</td>
            </tr>
            <tr>
                <th>JUAZEIRO</th>
                <td>R$ 89,60</td>
            </tr>
            <tr>
                <th>PETROLINA</th>
                <td>R$ 76,98</td>
            </tr>
            <tr>
                <th>RUY BARBOSA</th>
                <td>R$ 83,04</td>
            </tr>
            <tr>
                <th>SENHOR DO BONFIM</th>
                <td>R$ 78,35</td>
            </tr>
            <tr>
                <th>SERRINHA</th>
                <td>R$ 68,92</td>
            </tr>
            <tr>
                <th>TUCANO</th>
                <td>R$ 62,78</td>
            </tr>
            <tr>
                <th>TOTAL</th>
                <td>R$ 79,16</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <br>

    <!-- <div class="card p-1">

    </div> -->
  </div>

</template>

<script>

export default {
  name: 'Faturamento',
  data() {
    return {
            
    };
  },
  components: {
    
  }
}

</script>

<style scoped>
  .card {
    box-shadow: 0 3px 4px rgba(0, 0, 4, 10);
    min-height: 230px;
    /* border: 2px solid silver; */
    /* background-color: white !important; */
  }
</style>