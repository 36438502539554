<template>
  <div id="login">
    <!-- Login -->
    <div class="card">
      <div class="card-body">
        <!-- Header -->
        <div class="text-center">
          <img src="assets/img/wantel-w.png" alt="img">
          <h4 class="card-title text-light"><b>Wantel</b></h4>
          <h5 class="card-title text-light"><b>Sistema Auxiliar de Gerenciamento</b></h5>
        </div><hr>
        
        <div class="text-center text-light">
          <h5 class="card-subtitle mb-2"><b>Login</b></h5>
        </div>

        <!-- Formulário -->
        <form action="">
          <div class="form-floating">
            <input type="email" v-model="email" class="form-control" id="floatingInput" placeholder="name@example.com" required>
            <label for="floatingInput">E-mail</label>
          </div><br>

          <div class="form-floating">
            <input type="password" v-model="password" class="form-control" id="floatingPassword" placeholder="Senha" required>
            <label for="floatingPassword">Senha</label>
          </div>
        </form>
        <br>

        <button v-if="!loadingRequest" class="btn btn-outline-light w-100" @click="login()"><b>Entrar</b></button>
        <button v-if="loadingRequest" class="btn btn-light w-100" disabled><b><span class="spinner-border spinner-border-sm text-dark"></span> Autenticando...</b></button>
        <br>

        <!-- Alerts -->
        <Alerts v-if="alert" :alert="alert" :alertMsg="alertMsg" :alertStatus="alertStatus" style="margin-top:5px;"/>
      </div>
    </div>
  </div>
</template>

<script>
import { apiJson, apiMult } from "@/services/api"
import Alerts from "@/components/global/Alerts.vue"

export default {
  name: 'Login',
  data() {
    return {
      loadingRequest: false,
      alert: false,
      alertMsg: '',
      alertStatus: false,
      email: '',
      password: ''
    }
  },
  components: {
    Alerts
  },
  mounted() {
    // Adicione um event listener para a tecla "Enter"
    document.addEventListener('keypress', this.handleEnterKey)
  },
  beforeUnmount() {
    // Remove o event listener quando o componente for desmontado
    document.removeEventListener('keypress', this.handleEnterKey)
  },
  methods: {
    login () {
      this.loadingRequest = true
      this.alert = false
      this.alertMsg = false
      this.alertStatus = false

      if (this.email == '') {
        this.alert = true 
        this.alertMsg = `Preencha o campo "E-mail" corretamente!`
        this.loadingRequest = false
        return
      }
  
      if (this.password == '') {
        this.alert = true 
        this.alertMsg = `Preencha o campo "Senha" corretamente!`
        this.loadingRequest = false
        return
      }

      let formData = new FormData()
      formData.append('email', this.email)
      formData.append('password', this.password)

      apiJson.post('auth/login', formData).then((response) => {    
        if (response.data.error) {
          this.alertMsg = response.data.error
          this.alert = true
          this.alertStatus = response.data.status
          this.loadingRequest = false
          return
        }

        if (response.data.token) {
          this.alertMsg = response.data.success
          this.alert = true
          // this.alertStatus = response.data.status
          this.alertStatus = null

          this.insertStore(response)
     
          setTimeout(function() {
            // window.location.href = '/'
            window.location.href = '/relatorios/financeiro-resumo'
          }, 2000)
        }
      }).catch((error) => {
        this.alert = true
        this.alertMsg = error.response.data.message
        this.alertStatus = false
        this.loadingRequest = false
      })
    },
    insertStore (response) {
      let token = response.data.token
      let user_id = response.data.user_id
      let name = response.data.user_name
      let user_email = response.data.user_email  
      
      localStorage.setItem("token", token)
      localStorage.setItem("user_id", user_id)
      localStorage.setItem("user_name", name)
      localStorage.setItem("user_email", user_email)

      if (response.data.is_admin && response.data.is_admin != undefined && response.data.is_admin != null) {
        let is_admin = response.data.is_admin
        localStorage.setItem("is_admin", is_admin)
      }
    },
    handleEnterKey(event) {
      // Se a tecla pressionada for "Enter" e o botão estiver visível, chame a função de login
      if (event.key === 'Enter' && !this.loadingRequest) {
        this.login()
      }
    }
  }
}

</script>

<style scoped>
  /* #login { 
    width: 100vw;
    height: 100vh;
    background-image: url('@/assets/img/wantel_login.jpg');
    background-size: cover;
    background-position: center;
  } */

  .card {
    position: absolute; 
    top: 50%; 
    left: 50%; 
    transform: translate(-50%, -50%);
    font-family: 'Montserrat', sans-serif;
    background: linear-gradient(to right, #55e928, #0b961d);
    box-shadow: 0 8px 8px rgba(0, 0, 4, 10);
    min-width: 280px;
    /* border: 2px solid silver; */
  }

  .form-login {
    padding: 5px;
  }
  .form-control:focus {
    outline: none;
  }
  .form-control {
    outline: none; /* Remover a borda padrão */
  }

  .form-control:focus {
    outline: none; /* Remover a borda de destaque no foco */
  }
</style>