<template>
  <div id="main">
    <div class="pagetitle">
      <h1>Boletos</h1>
    </div>
    <br/>

    <!-- Paginação de Filtros -->
    <div class="mb-2">
      <button v-for="(value, key) in btnNavegacao" :class="value" :key="key" @click="funcBtnNavegacao(key)" :disabled="loading || (filteredItems.length <= 0 && key == 'Filtro')" style="margin: 1px">
        <b>{{ key }}</b>
      </button>
    </div>

    <!-- Filtro NFSE -->
    <div>
      <!-- Filtro no Back-end -->
      <div class="card col-md-12" v-if="navegacaoSelec == 'Busca'">
        <div class="card-body">
          <div class="row">
            <div class="col-md-4">
              <label for="start_date" class="form-label">Início Data Vencimento</label>
              <input type="date" v-model="filterStartPeriod" class="form-control" id="start_date" :disabled="loading"/>
            </div>

            <div class="col-md-4">
              <label for="end_date" class="form-label">Fim Data Vencimento</label>
              <input type="date" v-model="filterEndPeriod" class="form-control" id="end_date" :disabled="loading"/>
            </div>

            <div class="col-md-2">
              <label class="form-label">Avulso</label>
              <select v-model="boletoAvulso" class="form-select" aria-label="Default select example" :disabled="loading">
                <option value="null">Todos</option>
                <option value="Nao">Não</option>
                <option value="Sim">Sim</option>
              </select>
            </div>

            <div class="col-md-2" style="margin-top: 32px;">
              <button class="btn btn-primary" id="btnSub" @click="listBoletos()" :disabled="loading || filterStartPeriod == '' || filterEndPeriod == ''">Buscar</button>
            </div>
          </div>
        </div>
      </div>

      <!-- Filtro no Front-end -->
      <div class="card col-md-12" v-if="navegacaoSelec == 'Filtro'">
        <div class="card-body">
          <div class="text-end" style="margin-bottom: -10px;">
            <button class="btn btn-outline-primary btn-sm" @click="clearFilter()">Limpar</button>
          </div>

          <div class="row">
            <div class="col-md-3">
              <label class="form-label">Número</label>
              <input type="text" v-model="filterNumeroBoleto" class="form-control" placeholder="Ex.: 110820" oninput="this.value = this.value.replace(/[^0-9]/g, '');" :disabled="loading"/>
            </div>

            <div class="col-md-3">
              <label class="form-label">Referência</label>
              <input type="date" v-model="filterDataReferencia" class="form-control" :disabled="loading"/>
            </div>

            <div class="col-md-3">
              <label class="form-label">Vencimento</label>
              <input type="date" v-model="filterDataVencimento" class="form-control" :disabled="loading"/>
            </div>

            <div class="col-md-3">
              <label class="form-label">Tomador</label>
              <input type="search" v-model="filterDadosEmp" class="form-control" placeholder="Nome/CPF/CNPJ" :disabled="loading"/>
            </div>

            <div class="col-md-3" v-if="!boletoAvulso">
              <label class="form-label">Avulso</label>
              <select v-model="filterAvulso" class="form-select" aria-label="Default select example" :disabled="loading">
                <option value="">Todos</option>
                <option value="0">Não</option>
                <option value="1">Sim</option>
              </select>
            </div>

            <div class="col-md-3">
              <label class="form-label">Tipo</label>
              <select v-model="filterTipoConta" class="form-select" aria-label="Default select example" :disabled="loading || filterAvulso != ''">
                <option selected value="">Todas</option>
                <option value="PRE">Pré-Pago</option>
                <option value="POS">Pós-Pago</option>
              </select>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Exportar Boletos -->
    <ExportData v-if="!loading && navegacaoSelec == 'Busca'" :name="'boleto'" :filterStartPeriod="filterStartPeriod" :filterEndPeriod="filterEndPeriod" :param="boletoAvulso"/>

    <!-- Modal de Sincronização -->
    <SyncData v-if="!loading && navegacaoSelec == 'Busca'" :name="'boleto'"/>

    <hr v-if="!loading" />

    <!-- Tabela com a Lista de Boletos -->
    <div class="table-responsive" v-if="!loading && filteredItems.length > 0">
      <table class="table table-hover table-bordered text-center text-nowrap">
        <thead class="table-dark">
          <tr class="">
            <th scope="col">Número</th>
            <th scope="col">Avulso</th>
            <th scope="col">Referência</th>
            <th scope="col">Vencimento</th>
            <th scope="col">Valor</th>
            <th scope="col">Tomador</th>
            <th scope="col">Tipo</th>
            <th scope="col">CPF/CNPJ</th>
            <th scope="col">Situação</th>
          </tr>
        </thead>
        <tbody class="table-light">
          <tr v-for="item in paginatedItems" :key="item" typo="button" style="cursor: pointer">
            <th scope="row">{{ item.boleto_numero }}</th>
            <td>{{ item.boleto_avulso == 0 ? 'Não' : 'Sim' }}</td>
            <td>{{ formatarData(item.boleto_referencia) }}</td>
            <td>{{ formatarData(item.boleto_vencimento) }}</td>
            <td>{{ formataValor(item.boleto_valor) }}</td>
            <td class="text-start">{{ item.cliente_nome }}</td>
            <td>
              {{ item.cliente_tipo_conta.toUpperCase() }}
            </td>
            <td>
              <template v-if=" item.cliente_cpf_cnpj !== undefined && item.cliente_cpf_cnpj !== null && item.cliente_cpf_cnpj !== '' && item.cliente_cpf_cnpj.length == 11">
                <span>{{ formataCpf(item.cliente_cpf_cnpj) }}</span>
              </template>
              <template v-else>
                <span>{{ formataCnpj(item.cliente_cpf_cnpj) }}</span>
              </template>
            </td>
            <td>
              {{ item.boleto_situacao.toUpperCase() }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div  v-if="loading" style="margin-top: 10px; display: flex; justify-content: center; align-items: center;">
      <Loading/>
    </div>

    <!-- Paginação -->
    <Pagination v-if="filteredItems.length > 0 && !loading" :list="filteredItems" @updatePaginatedItems="updatePaginatedItems"/>
  </div>
</template>

<script>
import { apiJson } from "@/services/api"
import Loading from "@/components/global/Loading.vue"
import Pagination from "@/components/global/Pagination.vue"
import SyncData from "@/components/global/SyncData.vue"
import ExportData from "@/components/global/ExportData.vue"

export default {
  name: "Boletos",  
  data() {
    return {
      // Loading
      loading: false,
      btnNavegacao: {
        Busca: "btn btn-primary btn-sm",
        Filtro: "btn btn-outline-primary btn-sm",
        // 'exemplo': 'btn btn-outline-primary btn-sm',
      },
      navegacaoSelec: "Busca",
      filterStartPeriod: "",
      filterEndPeriod: "",
      filteredItems: [],
      filterNumeroBoleto: "",
      filterDataReferencia: "",
      filterDataVencimento: "",
      filterDadosEmp: "",
      filterTipoConta: "",
      filterAvulso: "",
      // Paginação
      listItems: [],
      paginatedItems: [],
      boletoAvulso: null
    }
  },
  components: {
    Loading,
    Pagination,
    SyncData,
    ExportData
  },
  mounted() {
    // this.listBoletos()
  },
  watch: {
    filterNumeroBoleto() {
      this.filterItems();
    },
    filterDataReferencia() {
      this.filterItems();
    },
    filterDataVencimento() {
      this.filterItems();
    },
    filterDadosEmp() {
      this.filterItems();
    },
    filterAvulso() {
      this.filterItems();
    },
    filterTipoConta() {
      this.filterItems();
    }
  },
  methods: {    
    listBoletos() {
      this.loading = true
      this.listItems = []

      if (this.filterStartPeriod == '' || this.filterEndPeriod == '') {
        this.$store.commit('setAlert', { show: true, status: false, msg: 'Preencha a Emissão corretamente.' });
        return
      }

      let formData = {
        start_date: this.filterStartPeriod,
        end_date: this.filterEndPeriod,
        boleto_avulso: this.boletoAvulso,
      }

      apiJson.post("financeiro/list-boletos", formData).then((response) => {
        if (response.data.error) {
          this.$store.commit('setAlert', { show: true, status: false, msg: response.data.error });
          this.loading = false
          return
        }

        this.listItems = response.data
        this.filteredItems = response.data
        setTimeout(() => {
          this.loading = false
        }, 1000)
        return

      })
      .catch((error) => {
        if (error.response) {
          if (error.response.status == 404) {
            this.$store.commit('setAlert', { show: true, status: false, msg: error.response.data.message });
            this.loading = false
          }
          console.log(error.response)
        } else {
          console.log(error)
        }
        this.loading = false
      })
    },
    clearFilter () {
      this.filterNumeroBoleto = '';
      this.filterDataReferencia = '';
      this.filterDataVencimento = '';
      this.filterDadosEmp = '';
      this.filterAvulso = '';
      this.filterTipoConta = '';
    },    
    filterItems() {
      // Lógica para filtrar os itens com base nos critérios definidos pelo usuário
      this.filteredItems = this.listItems.filter(item => {
        if (this.filterAvulso != '') {
          this.filterTipoConta = '';
        }
        // Verificar cada critério de filtro
        const boleto_numero = item.boleto_numero.toString().includes(this.filterNumeroBoleto)
        const boleto_referencia = !item.boleto_referencia || item.boleto_referencia.toString().includes(this.filterDataReferencia)
        const boleto_vencimento = !item.boleto_vencimento || item.boleto_vencimento.toString().includes(this.filterDataVencimento)
        const cliente_tipo_conta = item.cliente_tipo_conta.toString().toLowerCase().includes(this.filterTipoConta.toLowerCase())
        const boleto_avulso = item.boleto_avulso.toString().includes(this.filterAvulso)
        const dados_emp =
          (item.cliente_nome && item.cliente_nome.toLowerCase().includes(this.filterDadosEmp.toLowerCase())) ||
          (item.cliente_cpf_cnpj && item.cliente_cpf_cnpj.toString().includes(this.filterDadosEmp))

        return boleto_numero && boleto_referencia && boleto_vencimento && dados_emp && cliente_tipo_conta && boleto_avulso;
      });
    },
    updatePaginatedItems(newPaginatedItems) {
      this.paginatedItems = newPaginatedItems
      this.loading = false
    },
    funcBtnNavegacao(btn) {
      for (const key in this.btnNavegacao) {
        if (key == btn) {
          this.btnNavegacao[key] = "btn btn-primary btn-sm"
          this.navegacaoSelec = btn
        } else {
          this.btnNavegacao[key] = "btn btn-outline-primary btn-sm"
        }
      }
    },    
    formatarData(data) {
      if (data === "" || data === null || data === undefined) {
        return "-"
      }
      let partesDataHora = data.split(" ")
      let partesData = partesDataHora[0].split("-")
      let dataFormatada = `${partesData[2]}/${partesData[1]}/${partesData[0]}` // Formatação desejada
      return dataFormatada
    },
    formatarDataComp(data) {
      if (data === "" || data === null || data === undefined) {
        return "-"
      }
      let partesDataHora = data.split(" ")
      let partesData = partesDataHora[0].split("-")
      let dataFormatada = `${partesData[1]}/${partesData[0]}` // Formatação desejada
      return dataFormatada
    },
    formataCpf(valor) {
      // Formata o CPF conforme a máscara '###.###.###-##'
      const numeroString = valor.toString()
      const padrao = /(\d{3})(\d{3})(\d{3})(\d{2})/
      const resultado = numeroString.replace(padrao, "$1.$2.$3-$4")
      return resultado
    },
    formataCnpj(valor) {
      // Formata o CPF conforme a máscara '##.###.###/####-##'
      const numeroString = valor.toString()
      const padrao = /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/
      const resultado = numeroString.replace(padrao, "$1.$2.$3/$4-$5")
      return resultado
    },
    formataValor(valor) {
      // Verifica se o valor é um número
      if (typeof valor !== "number") {
        // Tenta converter para número
        valor = parseFloat(valor)
        // Verifica se a conversão foi bem-sucedida
        if (isNaN(valor)) {
          // Se não for um número válido, retorna o valor original
          return valor
        }
      }
      // Formata o valor como dinheiro
      return valor.toLocaleString("pt-BR", {
        style: "currency",
        currency: "BRL",
      })
    }
  },
}
</script>

<style scoped>
th, td {
  font-size: 0.7em;
}
</style>