<template>
  <!-- Loading -->
  <div class="row align-items-center">
    <div class="col-auto">
      <div class="spinner-border" style="color: #012970;" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
    <div class="col">
      <h3 class="mb-0" style="color: #012970;">Carregando...</h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "Loading",
  // props: ["item"],
};
</script>